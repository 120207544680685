import { render, staticRenderFns } from "./UploadBrokerBatch.vue?vue&type=template&id=98463cb0&scoped=true"
import script from "./UploadBrokerBatch.vue?vue&type=script&lang=js"
export * from "./UploadBrokerBatch.vue?vue&type=script&lang=js"
import style0 from "./UploadBrokerBatch.vue?vue&type=style&index=0&id=98463cb0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98463cb0",
  null
  
)

export default component.exports