<template>
    <div class="vx-row">
        <div class="vx-col w-full mb-base">
            <vx-card
                title="Upload Primis Report"
                :showOverflow="true"
                card-background="rgba(var(--vs-success),0.65)"
                title-color="#fff"
                subtitle-color="#fff"
                content-color="#fff"
            >
                <div id="div-with-loading" class="vx-row">
                    <div class="vx-col w-full sm:w-1/2">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <label class="vs-input--label text-white"
                                    >Description</label
                                >
                                <vs-textarea
                                    v-model="form.description"
                                    class="w-full"
                                ></vs-textarea>
                            </div>
                            <div class="vx-col w-full mb-4">
                                <ImportExcel
                                    :onSuccess="loadDataInTable"
                                    :fileChange="fileChange"
                                    :file="form.file"
                                />
                            </div>
                            <div class="vx-col w-full">
                                <vs-button
                                    @click="upload"
                                    :disabled="!validatedForm"
                                    icon-pack="feather"
                                    icon="icon-upload"
                                    color="success"
                                    class="mr-3"
                                >
                                    Upload
                                </vs-button>
                            </div>
                        </div>
                    </div>
                    <VuePerfectScrollbar
                        ref="chatLogPS"
                        class="vs-col w-full sm:w-1/2 mb-6 p-6 max"
                        :settings="scrollSettings"
                        :key="$vs.rtl"
                    >
                        <p
                            v-if="response && response.clients_added !== null"
                            class="text-white mb-4"
                        >
                            {{ response.clients_added }} Clients Added
                        </p>

                        <div v-if="response && response.add_errors">
                            <h5 class="text-white">Errors</h5>
                            <p
                                v-for="(error, index) in response.add_errors"
                                :key="index"
                                class="text-white"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </VuePerfectScrollbar>
                </div>
            </vx-card>
        </div>
        <div class="vx-col w-full mb-base">
            <vx-card
                title="Data Preview"
                v-if="tableData.length && header.length"
            >
                <vs-table
                    style="background-color: transparent"
                    pagination
                    :max-items="10"
                    :data="tableData"
                >
                    <template slot="header">
                        <h4 class="text-white">{{ sheetName }}</h4>
                    </template>

                    <template slot="thead">
                        <vs-th
                            class="text-success"
                            :sort-key="heading"
                            v-for="heading in header"
                            :key="heading"
                            >{{ heading }}</vs-th
                        >
                    </template>

                    <template
                        slot-scope="{ data }"
                        style="background-color: transparent"
                    >
                        <vs-tr
                            :data="tr"
                            :key="indextr"
                            v-for="(tr, indextr) in data"
                            style="background-color: transparent"
                        >
                            <vs-td
                                :data="col"
                                v-for="(col, indexcol) in data[indextr]"
                                :key="indexcol + col"
                            >
                                {{ col }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ImportExcel from '@/components/excel/ImportExcel'
import { http } from '@/services'

export default {
    name: 'UploadPrimisReportCard',
    mixins: [textFormat],
    components: {
        VuePerfectScrollbar,
        ImportExcel,
    },
    watch: {},
    computed: {
        subTitle() {
            return 'Primis report'
        },
        validatedForm() {
            if (this.form.description && this.form.file) return true
            return false
        },
    },
    data() {
        return {
            form: {
                file: null,
                description: 'Primis - ',
            },
            response: null,
            scrollSettings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.6,
            },
            tableData: [],
            header: [],
            sheetName: '',
        }
    },
    methods: {
        upload() {
            if (this.validatedForm) {
                this.$vs.loading({
                    container: '#div-with-loading',
                    scale: 0.6,
                })

                var formData = new FormData()
                formData.append('description', this.form.description)
                formData.append('file', this.form.file)
                formData.append('primis', true)

                http.post('clients_list', formData)
                    .then((response) => {
                        this.$vs.loading.close(
                            '#div-with-loading > .con-vs-loading'
                        )
                        this.$root.$emit('refreshBatchData')
                        this.form.description = 'Primis - '
                        this.form.file = null
                        this.response = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.loading.close(
                            '#div-with-loading > .con-vs-loading'
                        )
                    })
            }
        },
        loadDataInTable({ results, header, meta }) {
            this.header = header
            this.tableData = results
            this.sheetName = 'Preview Data' // meta.sheetName
        },
        fileChange(file) {
            this.form.file = file
        },
    },
}
</script>

<style scoped>
.max {
    max-height: 300px;
}
</style>
