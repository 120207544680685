<template>
    <div>
        <UploadPrimisReportCard />

        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <PrimisBatch />
            </div>
        </div>

        <UploadBrokerBatchCard />

        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <BrokerBatch />
            </div>
        </div>
    </div>
</template>

<script>
import UploadPrimisReportCard from './Cards/UploadPrimisReport'
import UploadBrokerBatchCard from './Cards/UploadBrokerBatch'
import PrimisBatch from './Cards/PrimisBatch'
import BrokerBatch from './Cards/BrokerBatch'

export default {
    name: 'BatchClientsView',
    components: {
        UploadPrimisReportCard,
        UploadBrokerBatchCard,
        PrimisBatch,
        BrokerBatch,
    },
}
</script>
