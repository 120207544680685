<template>
    <vx-card title="Primis Report Uploads" collapse-action>
        <vs-table
            v-if="data && data.data"
            :data="data.data"
            :sst="true"
            @sort="handleSort"
            class="mb-base"
            stripe
        >
            <template slot="thead">
                <vs-th sort-key="date_added">Date Added</vs-th>
                <vs-th sort-key="description">Description</vs-th>
                <vs-th sort-key="">Clients</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        {{ formatDateAndTime(tr.date_added) }}
                    </vs-td>
                    <vs-td>
                        {{ tr.description }}
                    </vs-td>
                    <vs-td>
                        {{ tr.clients_count }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            v-if="data.number_of_pages > 1"
            :total="data.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

export default {
    name: 'PrimisBatchCard',
    mixins: [textFormat, tableHelpers],
    mounted() {
        this.getPage()
        this.$root.$on('refreshBatchData', (data) => {
            this.getPage()
        })
    },
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {
            pageNumber: 1,
            itemsPerPage: 10,
            selected: null,
            queriedItems: 20,
            broker: null,
            filter: 'primis',
            sort: null,
            data: [],
        }
    },
    computed: {},
    methods: {
        getPage(searchQuery = null) {
            http.get('batch_list', {
                params: {
                    filter: this.filter,
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: searchQuery,
                    sort: this.sort,
                },
            })
                .then((response) => {
                    this.data = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        handleSort(key, value) {
            this.sort = [key, value]
            this.getPage()
        },
    },
}
</script>

<style></style>
